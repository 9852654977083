import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
    selector: '[pageContent]',
    standalone: false
})
export class PageContentDirective {
    constructor(public viewContainerRef: ViewContainerRef) {
    }
}
